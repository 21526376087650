import React from 'react'
import { Helmet } from 'react-helmet'
import ContactForm from '../components/ContactF'
import { ScrollToTop } from '../utils'

const Contact = () => {
  return (
    <div>
      <Helmet>
          <title>Contact Us | TRG Overseas</title>
          <meta name="description" content="Want to settle/study abroad - Contact Us" />
        </Helmet>
      <ScrollToTop/>
      <ContactForm/>
    </div>
  )
}

export default Contact