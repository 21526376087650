import React from 'react'
import { Helmet } from 'react-helmet'
import ContactBanner from '../components/contact'
import NZ from '../components/Nz'
import { ScrollToTop } from '../utils'

const StudyNZ = () => {
  return (
    <>
        <Helmet>
          <title>Study In New Zealand | TRG Overseas</title>
          <meta name="description" content="The education system in New Zealand is enormously diverse and one of the best in the world, maintaining excellent standards in literacy, mathematics and sciences and ranking well consistently by global standards."/>
        </Helmet>
        <ScrollToTop/>
        <NZ/>
        <ContactBanner/>
    </>
  )
}

export default StudyNZ