import styled from "styled-components";

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;

    list-style: none;
    overflow-y: hidden;

    transition: transform 320ms ease-in;
    transform: ${props => props.show ? 'translateX(0)' : 'translateX(100%)'};
    .content{
        background-color: whitesmoke;
        width: 320px;
        height: 100vh;
        z-index: 21474836;
    
        a{
            text-decoration: none;
            color: #DC3545;
            font-weight: 700;
        }
    }
    @media screen and (max-width: 420px){
        .content{
            width: 100vw;
            height: 100vh;
            display: flex;
            justify-content: center;
        }
    }
`;

export const Content = styled.div`
    z-index: 21474836;
    padding-top: 70px;
    margin-bottom: 30px;
    li{
        padding: 6px 25px;
        margin: 5px 10px;
        width: 300px;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1.2px;
        transition: all 300ms ease-in;
        cursor: pointer;
    }
    .active{
        li{
            background-color: #00000030;
            border-radius: 6px;
            font-weight: 700;
            font-size: 20px;
            color: #e62e41;
        }
    }
`;