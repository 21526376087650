import React from 'react'
import { Content, Wrapper } from './guide.style'

import ForumIcon from '@mui/icons-material/Forum';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';


const Guide = () => {
  return (
    <Wrapper>
        <h1>Our 6-Step Guidance Process for Student Visa</h1>
        <Content>
            <div className='box'>
                <div className='icon'>
                    <ForumIcon id='i1'/>
                </div>
                <div>
                    <h2>Free Counselling</h2>
                    <p>We provide free counselling to perspective students by our expert education consultants who are graduated from foreign Universities.</p>
                </div>
            </div>
            <div className='box'>
                <div className='icon'>
                    <ContentPasteSearchIcon id='i2'/>
                </div>
                <div>
                    <h2>Identify Course and University</h2>
                    <p>We guide you through a maze of questions to find solutions best suited to your profile ensuring you choose the best academic path to achieve your career goals based on your aptitude, ability and interest.</p>
                </div>
            </div>
            <div className='box'>
                <div className='icon'>
                    <DocumentScannerIcon id='i3'/>
                </div>
                <div>
                    <h2>Application Guidance</h2>
                    <p>We give special attention to your application whether it is paper based or online. We see to it that your required documents are thoroughly compiled, attested, highlighting the areas essential for a well presented error free application. We have dedicated team to guide our students through visa processing</p>
                </div>
            </div>
            <div className='box'>
                <div className='icon'>
                    <EmojiObjectsIcon id='i4'/>
                </div>
                <div>
                    <h2>Test Preparation</h2>
                    <p>We provide the best training for IELTS, PTE, CELPIP and Spoken English. Every student is unique and we follow a mentoring model where every student is given personal attention. Our batch size is small and their faculty ensures that the students perform to the best of their ability.</p>
                </div>
            </div>
            <div className='box'>
                <div className='icon'>
                    <CreditCardIcon id='i5'/>
                </div>
                <div>
                    <h2>Finance Guidance</h2>
                    <p>We give complete guidance on Educational Loans offered by Financial Institute and Banks. We also provide information to students about the various International, Institutional and National scholarships.</p>
                </div>
            </div>
            <div className='box'>
                <div className='icon'>
                    <PeopleAltIcon id='i6'/>
                </div>
                <div>
                    <h2>End to End services</h2>
                    <p>Our services are open and never ends at a certain point. We will ensure that the services and help will be continued even after completion of course.</p>
                </div>
            </div>
        </Content>
    </Wrapper>
  )
}

export default Guide