import React from 'react'
import { Helmet } from 'react-helmet';
import ContactBanner from '../components/contact'
import IND from '../components/Ind'
import { ScrollToTop } from '../utils';

const StudyIND = () => {
  return (
    <>
        <Helmet>
          <title>Study In India | TRG Overseas</title>
          <meta name="description" content="India's diversity and history offer a wide array of options for any student. India's educational system is as vast and diverse as the country itself. While living in India can be challenging for international students, it is an affordable and rewarding experience."/>
        </Helmet>
        <ScrollToTop/>
        <IND/>
        <ContactBanner/>
    </>
  )
}

export default StudyIND