import React, { Component } from "react";
import { Content, Wrapper } from './testimonials.style'
import Slider from "react-slick";
import { testimonials } from "../../data";

import PersonIcon from '@mui/icons-material/Person';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, transform:'scale(1.5)', marginTop:'-15px'}}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, transform:'scale(1.5)',marginTop:'-15px'}}
        onClick={onClick}
      />
    );
  }

export default class Testimonials extends Component {
    render() {
      var settings = {
        dots: false,
        infinite: true,
        accessibility: true,
        speed: 800,
        autoplay: true,
        autoplaySpeed: 6000, 
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 950,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
      return (
        <Wrapper>
            <h1>RECENT CLIENTS</h1>
            {/* <h2>WHAT OUR CLIENTS HAVE TO SAY ABOUT US!</h2> */}
        <div className="box">
          <Slider {...settings}>
            {testimonials.map((item) => (
                <Content key={item.id}>
                    <div className="card">
                        <div className="head">
                            <div className="icon">
                                <PersonIcon id='picon'/>
                            </div>
                            <div>
                                <h3>{item.name}</h3>
                                <p>{item.country}</p>
                            </div>
                        </div>
                        <div className="desc">
                            <p>{item.desc}</p>
                        </div>
                    </div>
                </Content>
            ))}
          </Slider>
          </div>
        </Wrapper>
      );
    }
}
