import { createSlice } from '@reduxjs/toolkit';

const intialState = {
    value: false,
}

const saSlice = createSlice({
    name:'sa',
    initialState: intialState,
    reducers: {
        active: (state) =>{
            state.value = true;
        },
        notActive: (state) =>{
            state.value = false;
        },
    },
})

export const { active,notActive } = saSlice.actions

export default saSlice.reducer