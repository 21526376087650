export const sliderItems = [
    {
      id: 1,
      img: "/images/uk.jpg",
      desc: "UK has been the dream destination of international students for decades and the reasons are obvious. It is home to the world’s most respected universities like Oxford and Cambridge. Infact, the advantage of studying in the UK are numerous.",
    },
    {
      id: 2,
      img: "/images/usa.jpg",
      desc: "The United States boasts of one of the world's best education systems. They have excellent programmes in all the disciplines they have to offer.",
    },
    {
      id: 3,
      img: "/images/canada.jpg",
      desc: "Canada’s high academic standards and rigorous quality controls ensure that the degree you obtain here will open the doors to your dream career and benefit your professional trajectory in the long-term.",
    },
    {
      id: 4,
      img: "/images/nz.webp",
      desc: "New Zealand is one of the world’s best places to live and study. New Zealand is the world’s second most peaceful country with lowest rates of corruption. New Zealanders are welcoming, friendly and respectful to other cultures.",
    },
    {
      id: 5,
      img: "/images/australia.jpg",
      desc: "Australia has the third highest number of international students in the world, behind only the United Kingdom and the United States. Australia has seven of the top 100 universities in the world!",
    },
    {
      id: 6,
      img: "/images/india2.jpg",
      desc: "India's diversity and history offer a wide array of options for any student. India's educational system is as vast and diverse as the country itself. While living in India can be challenging for international students, it is an affordable and rewarding experience.",
    },
];
export const flags = [
    {
      id: 1,
      img: "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg",
      alt: "UK Flag",
      title: "UNITED KINGDOM",
      nav: "/study-in-uk",
    },
    {
      id: 2,
      img: "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg",
      alt: "Australian Flag",
      title: "AUSTRALIA",
      nav: "/study-in-australia",
    },
    {
      id: 3,
      img: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_%28Pantone%29.svg",
      alt: "Canadian Flag",
      title: "CANADA",
      nav: "/study-in-canada",
    },
    {
      id: 4,
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/Flag_of_New_Zealand.svg/800px-Flag_of_New_Zealand.svg.png",
      alt: "New Zealand's Flag",
      title: "NEW ZEALAND",
      nav: "/study-in-nz",
    },
    {
      id: 5,
      img: "https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/800px-Flag_of_the_United_States.svg.png",
      alt: "USA Flag",
      title: "UNITED STATES",
      nav: "/study-in-usa",
    },
    {
      id: 6,
      img: "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/800px-Flag_of_India.svg.png",
      alt: "Indian Flag",
      title: "INDIA",
      nav: "/study-in-india",
    },
];

export const testimonials = [
    {
      id: 1,
      name: 'Atul Malik',
      country: 'India',
      desc: 'Post Study Work in 2022 in United Kingdom',
    },
    {
      id: 2,
      name: 'Pallavi Sura',
      country: 'India',
      desc: 'Spouse Visa for United Kingdom',
    },
    {
      id: 3,
      name: 'Gehenaben',
      country: 'India',
      desc: 'Settlement Visa for United Kingdom',
    },
    {
      id: 4,
      name: 'Ashish Singh',
      country: 'India',
      desc: 'Fiancée visa for United Kingdom',
    },
    {
      id: 5,
      name: 'Diljit Singh',
      country: 'India',
      desc: 'Spouse visa for UK',
    },
    {
      id: 6,
      name: 'Tarun Singh',
      country: 'India',
      desc: "Spouse visa for UK",
    },
    {
      id: 7,
      name: 'Tannia D Sauza',
      country: 'India',
      desc: 'Family Settlement Visa UK',
    },
    {
      id: 8,
      name: 'Naser Khan',
      country: 'India',
      desc: 'EUSS scheme family permit',
    },
    {
      id: 9,
      name: 'Siraj K Elahi',
      country: 'India',
      desc: 'Family Permit',
    },
];

export const why = [
  { 
    id: 1,
    doubt: "Tourist Visa",
    ans: "Depending upon the requirement of a client, we can help him to get the Tourist Visa for the selected country at nominal rates"
  },
  {
    id: 2,
    doubt: "International Study Needs",
    ans: "We are the one stop solution for students to know the requirement for international study"
  },
  {
    id: 3,
    doubt: "Student Centeric Counseling",
    ans: "We provide counselling to each student on case by case & remove barriers to information for our clients through personal service and innovative practices."
  },
  {
    id: 4,
    doubt: "Career Counseling",
    ans: "Our expert consultants help you identify and secure the university or college where you can thrive We combine our vast experience in the education industry and the latest technology available to help you make the most informed decision possible."
  },
  {
    id: 5,
    doubt: "Best ILETS and PTE Training",
    ans: "We provide the best training in English Language to our clients so that they can achieve their desired grades in the English Language Tests."
  },
  {
    id: 6,
    doubt: "Personalized attention to the application",
    ans: "We provide personalized attention to each application of the student"
  },
  {
    id: 7,
    doubt: "Experienced Professionals",
    ans: "Our counsellors are highly qualified in handling the students right from university admission process till the study visa gets processed. "
  },
  {
    id: 8,
    doubt: "99% visa success rate",
    ans: "We have 99% visa success rate for all our student applications."
  },
  {
    id: 9,
    doubt: "End to End Services",
    ans: "Our support does not end when you receive your university acceptance letter. We are dedicated to  support at you every stage of your study abroad journey"
  },
  {
    id: 10,
    doubt: "Immigration",
    ans: "We provide unmatched expertise in processing of immigration applications for all countries listed."
  },
]

export const ukR = [
  {
    id: 1,
    title:"World Renowned Universities",
    content: "Degrees from UK higher education providers are respected around the world, and the choice of subjects you can study is extensive. A degree from any British university will look great on a CV, and when looking for work in the future, no matter the industry and the location, having a qualification from the UK will be a great selling point. British higher education providers are recognised internationally for their creative and challenging environments, that help push students to be their best. Their standards are highly regarded, with experts on many academic topics, and they’re generally high in internationally university rankings."
  },
  {
    id: 2,
    title: "Quality Education",
    content: "The UK has retained its position as a popular destination among international students owing to its long standing tradition of providing quality education. The standard of teaching and research at UK universities and colleges is routinely assessed and graded by official organisations to ensure that set benchmarks are met. Although UK institutes undertake the responsibility of ensuring standard and quality of different programmes themselves, independent audits are carried out by the Quality Assurance Agency for Higher Education (QAA). Professional bodies may also guide the curriculum and perform reviews on individual departments in an institute. Colleges in Scotland may undergo reviews by Her Majesty’s Inspectorate of Education.",
  },
  {
    id: 3,
    title: "Work While You Study",
    content: "Generally, an international student can work up to 20 hours a week during term-time, and ten hours when school is out. This allows students to take on a part-time job or an internship, to learn new skills and earn money while studying. Your university may even help you secure an internship as part of your course, which will most certainly give you a competitive edge when you graduate",
  },
  {
    id: 4,
    title: "Work Permit After Study",
    content: "The UK Government has also announced a new post-study visa that will allow international students who graduate from the summer of 2021 to stay and work in the UK for a further two years.",
  },
  {
    id: 5,
    title: "Scholarship and Financial Support",
    content: "International students can enjoy some financial benefits when choosing the UK. Firstly, a degree in the UK takes less time to complete than in other countries. In many countries it takes four years, plus two or three extra years to complete a postgraduate degree. In the UK it takes three years for an undergraduate degree and then one extra to complete a postgraduate qualification (unless you are a medical or research student). International students can also get financial help when studying in the UK, in the form of scholarships, grants and bursaries.  Furthermore, living costs in the UK, especially when outside of cities like London, are reasonable.",
  },
  {
    id: 6,
    title: "The UK is Multicultural",
    content: "The UK is a multicultural country and is also immensely popular with international students. In fact, the UK is the second most popular destination for overseas students in the world. This diversity means our campuses are alive with different cultures. You can mix with people from around the world and learn so much more than just what you are studying on your degree.  ",
  },
  {
    id: 7,
    title: "Health Benefits",
    content: "Indian students in the UK are eligible to receive free medical treatment by the National Health Service (NHS) if enrolled in a full-time course. Your spouse/partner and/or dependent children will also be exempt from payment if they reside with you during the course of your study programme. Your college or university may have a special health policy for students too.",
  },
  {
    id: 8,
    title: "Strong Research Infrastructure",
    content: "As a research student evaluating the benefits of studying in UK, it will be interesting for you to note that the country puts across 6.4% of global journal articles, 11.6% of citations and 15.9% of the world’s most highly-cited articles, even though it constitutes just 0.9% of world’s total population. The latest Research Excellence Framework (REF) which checks the quality of research conducted by more than 154 leading universities and colleges in the UK, classified 30% of the research as ‘world-leading’ and 46% as ‘internationally excellent’. ",
  },
]

export const usaR = [
  {
    id: 1,
    title: "Academic excellence",
    content: "The US boasts of some of the finest universities, a lot of which consistently rank in the world university rankings. American institutions are also known to have high academic standards, follow rigorous practices to maintain quality and are well-supported to be able to offer excellent education to its students. As per the QS World Ranking 2019. 33 of the top 100 universities are from the US. Similarly, Times Higher Education Ranking has also ranked seven of the American universities in its top 10 list of universities."
  },
  {
    id: 2,
    title: "Flexible education system",
    content: "American universities and colleges offer a myriad of courses and programs to choose from. You have the freedom to not only select the course content, but also the structure. At the undergraduate level, you have the liberty to pursue different courses before they you declare your major at the end of the second year. This helps to explore your subject interest and then decide without much hurry. Similarly, for your graduate studies, you can choose your preference and when you progress for your dissertation, you can focus on the ideas you want to emphasise upon."
  },
  {
    id: 3,
    title: "Excellent support system for international students",
    content: "American universities understand the struggles of international students and therefore conduct regular orientation programs, workshops and trainings to offer assistance. In fact, the international student office helps students like you to get accustomed to a new kind of lifestyle – whether it is an academic query, cultural or social, the staff will be there to assist you round the clock."
  },
  {
    id: 4,
    title: "Cultural diversity",
    content: "The US is a melting pot of different cultures, races and ethnicities. Its diverse environment ensures that there is acceptance among all communities and there is no room for any sort of discrimination. You’ll be learning with students from different regions of the world thereby making it a rich and stimulating education experience. Growing in the midst of diversity will provide you with strong personality traits and skills that will be valuable in the international market. These days employers prefer students with a multi-cultural background, which you would get a great taste of in the US. A one of a kind international exposure, the US will help you explore variety of cuisines, customs, festivals and art too."
  },
  {
    id: 5,
    title: "Lively and vibrant campus life",
    content: "It’s a known fact that campus life of the US is matchless. Irrespective of the university you study in, you will find yourself in the midst of new cultural experiences and the American way of life. Embrace it and open yourself up to new ideas and new people."
  },
]

export const canadaR = [
  {
    id: 1,
    title: "Academic excellence",
    content: "One of the most important reasons why students choose to study in Canada is because of the high quality of education. A degree from a Canadian university acts as a mark of trust and excellence. 26 of Canada’s universities rank in the QS World University Rankings 2019 and 27 of them in the THE World University Rankings 2019."
  },
  {
    id: 2,
    title: "Research Opportunities Are In Abundance",
    content: "The cardinal reason why Canadian education stands peculiar is because of its strong focus on research and development. If you’re a research scholar, there can be no country better than Canada. The government of Canada encourages research in the disciplines of medicine, telecommunication, agriculture, environmental science and technology."
  },
  {
    id: 3,
    title: "Affordability",
    content: "Tuition fee in Canada, when compared to universities in the US and Australia, is fairly reasonable. And when considering the return on investment, it is quite a feasible option for Indian students. International students can also apply to a range of scholarships to cut down on their educational expenses."
  },
  {
    id: 4,
    title: "Diverse Culture",
    content: "In Canada, you’ll find extremely warm and welcoming people who come from varied ethnic groups with different cultures and lifestyle. The multicultural environment in Canada promotes harmony and peaceful living. In fact, you will find a lot of Indians studying, working and living here."
  },
  {
    id: 5,
    title: "Safety",
    content: "Canada has been consistently ranked as one of the safest nations in the world. In 2018, the Global Peace Index*, listed Canada as the sixth peaceful country across the globe. As international student, you’ll enjoy the same rights as any other Canadian student."
  },
  {
    id: 6,
    title: "Work Opportunities",
    content: "Thanks to its special regulations, Canada provides all its international students with an opportunity to work for up to 20 hours every week during their semesters and full-time during the summer and winter breaks. To work on-campus or as an intern in any company, you would not require any additional work permit as your study permit is enough to help you find a part time job, making your experience of staying in Canada, hassle free and unparalleled."
  },
  {
    id: 7,
    title: "Vibrant campus Life",
    content: "At any of the Canadian campuses you can expect events, fests, and other activities being a regular part of the academic year. This creates a happy and lively environment for all the students, helps you network and meet new people besides keeping the homesickness away. In crux, you will be in a home away from home."
  },
  {
    id: 8,
    title: "Immigration opportunities",
    content: "Canada’s Post-Graduation Work Permit Program (PWPP) allows students to stay and work in Canada for up to three years after completion of their graduation. You can gain some international work experience and apply for permanent residency later on."
  },
]

export const nzR = [
  {
    id: 1,
    title: "Universities that are globally recognised",
    content: "Why settle for an average university when you can study in a university that is globally recognised and well appreciated. All universities in New Zealand take pride in being showcased amongst the best in the world. According to QS World University Ranking 2021, all the 8 universities in the county fall under the top 3% globally.  The universities cater to the academic needs of the student in class and are a powerhouse of knowledge. The universities enrol fewer students and have excellent student-teacher ratio (an average of 6.7) which allows you to have a close interaction with the tutors. The institutions have a well-balanced curriculum with equal weightage on practical and theoretical knowledge."
  },
  {
    id: 2,
    title: "Quality of education",
    content: "With superior quality education, excellent labs, research centres and educators who are masters in their field of expertise, one can expect nothing but the best in terms of the quality of education in New Zealand. All the programs offered by the public universities in New Zealand go through stringent quality checks. All non-university education programs need to be approved by the New Zealand Qualifications Authority (NZQA), a New Zealand government organization responsible for quality control. The education system here is based on the British education model, and the students have an opportunity to get hands-on experience and knowledge to prepare them to excel anywhere in the world. The degrees you obtain at any university in New Zealand are at par with Universities in Canada, Australia, the USA and the UK. Graduates from Universities in New Zealand enjoy global reputations for technical innovation, research and are in great demand around the world."
  },
  {
    id: 3,
    title: "Diversity in courses",
    content: "The Universities in New Zealand offer a wide spectrum of courses in diverse fields that will have you spoilt for choice. From engineering to social service, one can pick a course that appeals to them, or a course that take them to greater heights. Excellent quality of education, superior teaching standards and an opportunity to learn from the best will surely give students an edge over the others. Industry-specific knowledge and hands-on experience is often a part and parcel of any course which ensures that the students graduating from these colleges are instantly absorbed by the finest employers across the world."
  },
  {
    id: 4,
    title: "Lower living expenses and affordable educational costs",
    content: "Educational expenditure for similarly structured programs and qualifications are much lower in universities in New Zealand when compared with destinations such as the USA, the UK, etc. International students also get a chance to choose from a wide range of scholarships available for their studies. Scholarships are provided by the university and the Government and are mainly based on the previous or on-going academic performances of the applicant. Receiving an educational grant will allow you to further reduce your expenses."
  },
  {
    id: 5,
    title: "Student-friendly requirements",
    content: "You might have come across many challenges and a long list of requirements that you must take care of if you wish to study abroad. This, however, is not the case in New Zealand. The requirements are relatively relaxed. Though New Zealand is quickly becoming a preferred destination for students from across the world, you could still easily get into a university of your choice with the help of the right guidance and preparation. There is no age limitation to study in universities here, and students with average grades can also aspire to get an excellent education in New Zealand."
  },
  {
    id: 6,
    title: "Work while you study",
    content: "In order to ease your financial situation, you can choose to take up a part-time job while you study in New Zealand. A student can work up to 20 hours a week if they are studying full-time and can work full time during scheduled breaks, holidays, etc, provided your course is worth 120 credits or more. The pay is handsome, and one can choose to work across various industries that may appeal to them. Students pursuing Ph.D. and similar qualifications can also work on campus and gain ample experience while studying."
  },
  {
    id: 7,
    title: "Stay back period post-education",
    content: "A lot of countries are constantly changing their stay back policies for students, and many do not bear good news for students. However, New Zealand offers up to three years of a stay back period depending on the course that you are studying. The nature of the course, duration, etc determine the stay back period, but one can be assured that he or she will have an opportunity to look for jobs and to work after finishing their education here."
  },
  {
    id: 8,
    title: "Safe and Secure",
    content: "You can be assured to have a safe and stable life whilst you study in your dream institution in New Zealand. A stable government, low crime rate and friendly people make this country one of the safest countries in the world. New Zealand is listed in the 3rd position of the Global Peace Index. International students are especially taken care of by the strict guidelines formulated under The Code of Practice for The Pastoral Care of International Students. It is mandatory for every education provider to abide by this code. The residents of this country are welcoming and embracing students from across the world who choose to study in Universities located across New Zealand."
  },
]

export const ausR = [
  {
    id: 1,
    title: "Top quality universities",
    content: "International students have a wide variety of choice when it comes to studying in Australia. Australia is home to 43 universities in total, with 40 Australian, two international and one private university. It’s a case of quality as well as quantity, with six Australian universities ranking in the internationally renowned top 100."
  },
  {
    id: 2,
    title: "Cultural diversity",
    content: "Australia is a diverse melting pot of cultures. The sheer amount of cultures that abound offers the chance to step outside of your usual comfort zone and experience something new, but also to feel a sense of belonging in the multicultural setting. Some of the benefits of living in a multicultural society include wonderful culinary offerings, public international celebrations and the chance to learn a different language."
  },
  {
    id: 3,
    title: "Any major is welcome",
    content: "Since Australia’s universities are some of the top ranked in the world, it’s no surprise that they offer a multitude of different degrees and majors. Whether you are studying engineering or English, medicine or Mathematics, there are plenty of different options and combinations to choose from when you study in Australia. It could be a good idea to contact your short list of universities prior to see what they offer and whether you meet the eligibility requirements."
  },
  {
    id: 4,
    title: "The Great Outdoors",
    content: "Australia is famous for its diverse terrain. The Outback is legendary for its broad plains and unusual animals. If you’re a beach lover, you are spoiled for choice with thousands of kilometres of pristine coast to choose from. During your holidays, you can dive or snorkel on the gorgeous Great Barrier Reef. Bushwalking or kayaking are often achievable within a day trip."
  },
  {
    id: 5,
    title: "Easy access to student visas",
    content: "If you’re looking to get a student visa, Australia offers a streamlined process for the Student Visa (subclass 500). There are several requirements you will need to meet for your application to be approved, including being accepted into an educational institution and having sufficient financial capacity. You will also be required to get an appropriate level of health insurance for the duration of your stay."
  },
  {
    id: 6,
    title: "Internship Availability",
    content: "Some Australian universities may offer internships or work opportunities to their students. If this is an option that interests you, be sure to reach out to your chosen educational institution to find out any requirements."
  },
  {
    id: 7,
    title: "Vibrant city life",
    content: "Australian universities are located in both rural and urban settings. Wherever you choose to study you will be able to travel to a number of neighbouring cities with ease. Each one offers a multitude of unique experiences, from the spectacular Sydney beach scene to the quirky shopping boutiques of Melbourne."
  },
  {
    id: 8,
    title: "Wildlife",
    content: "Australia has some of the most diverse wildlife on earth. If you’re studying rurally, you might be lucky enough to experience some in the wild. Even if you don’t there are plenty of wildlife parks that offer close encounters with koalas, kangaroos, crocodiles and more."
  },
  {
    id: 9,
    title: "Easy communication",
    content: "Australians speak English, which makes it easy for most international students to understand and be understood. However, the slang may take a little longer to master! If you’re struggling to keep up with conversation try asking someone to explain the slang to you. You’ll be amazed at the variations Australians have come up with."
  },
  {
    id: 10,
    title: "Incredible work opportunities",
    content: "If you truly enjoy your time studying in Australia, you may be tempted to stay on longer. Australia also offers a Temporary Graduate Visa (subclass 485) that allows some international students to stay in Australia and continue to work after they have graduated. International study in Australia offers a wide range of rewarding experiences as well as the possibility for a world-class education. If you’re thinking about studying abroad in Australia, these 10 points are really just the beginning of what Australia can offer."
  },
]

export const indR = [
  {
    id: 1,
    title: "Universities Are Well-Ranked...and Climbing",
    content: "If you’re considering studies in engineering and technology,  in particular, the quality of India’s offerings in these areas are particularly impressive  -- known for their constant pursuit of teaching excellence and boundary-pushing research. But India’s offerings are far from limited to these fields of studies. From politics to philosophy and biology to business there’s something for everyone in India’s massive higher education system. Which brings us to our next point."
  },
  {
    id: 2,
    title: "Home to the World’s Second-Largest Higher Education System",
    content: "What do you get when you combine 343 universities and 17,000 colleges? The second-largest education system on the planet. Comprising bachelor’s, master’s and doctoral degrees, the vastness of the Indian higher education system and its global network of students and faculty directly translates to increased diversity and enhanced opportunities for both academic and personal enrichment."
  },
  {
    id: 3,
    title: "Unique Courses Abound",
    content: "India’s higher education system isn’t just vast in size; it’s also vast in academic offerings. The country’s rich past and vibrant future mean a breadth and depth of courses are available -- from the classic to the cutting edge. In addition to being exposed to the latest frontiers of science and technology, students also have access to traditional subjects, such as Ayurveda, Sanskrit and Hindi."
  },
  {
    id: 4,
    title: "It’s Affordable",
    content: "Compared to many of the world’s finest institutions, India’s low cost of education is a bargain. Additionally, various scholarship, loan, and financial schemes are available to offset the cost. But it’s not just less expensive tuition fees which make studying in English a smart financial choice. The cost of living in India is also budget-friendly. How much so? According to Numbeo, rents in the US and UK are 509 percent and 456 percent higher, respectively, than in India."
  },
  {
    id: 5,
    title: "It’s Diversity Extends Beyond Academia",
    content: 'If your goal is to see the world, a visit to India is an amazing start. This large country offers incredible things to see and do -- from the mountains of the Himalayas to remarkable scenic -- and often undiscovered -- beaches like Goa, Lakshadweep and Andamans. When it comes to hospitality, meanwhile, Indians can’t be beat. Where else can you find a popular saying insisting "Athithi Devo Bhava" or “the guest is God.” Factor in opportunities to explore your spirituality, magnificent history and architecture, mouthwatering cuisine and extraordinary shopping, and the list of reasons to visit India continues to grow.'
  },
  {
    id: 6,
    title: "It’s Economy Is On the Rise",
    content: "India’s economy continues to surge. Not only do experts predict that it will be the world’s third largest economy by 2030, but is also expected to grow at the fastest rate in the decade ahead. What does this mean for students who choose to study there? A front row seat during a particularly exciting time in history, global connections, and an inside edge toward understanding the dynamic global business landscape. "
  },
]
