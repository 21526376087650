import React from 'react'
import { Content, Wrapper } from './usa.style'
import { usaR } from '../../data'

const USA = () => {
  return (
    <Wrapper>
        <div className='banner'>
            <div className='image'>
                <img src="/images/USA_1.jpeg" alt="Study In USA"/>
            </div>
            <div className='text'>
                <h3>STUDY IN USA</h3>
                <p>
                    United States of America boasts of one the world's biggest education systems. They have excellent programmes in all the disciplines they have to offer.
                </p>
            </div>
        </div>
        <Content>
            <h3>Top Reasons To Choose USA For Overseas Studies</h3>
            {
                usaR.map((item) => (
                    <div className='reason' key={item.id}>
                        <div className='heading'>
                            <p>{item.title}</p>
                        </div>
                        <div className='content'>
                            <p>{item.content}</p>
                        </div>
                    </div>
                ))
            }
        </Content>
    </Wrapper>
  )
}

export default USA