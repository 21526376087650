import styled from 'styled-components'

export const Wrapper = styled.div`
    /* position: absolute; */
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* justify-content: center; */
    width: 100vw;
    z-index: 1;
    background-color: white;
    .logo{
        margin-left: 20px;
        margin-bottom: 10px;
        /* position: absolute;
        left: 20px;
        top: 0; */
        img{
            height: 75px;   
        }
    }
    .menu{
        display: none;
        padding: 5px 10px;
    }
    .close{
        color: black;
        padding: 5px 6px;
        /* padding-bottom: 2px; */
        border-radius: 50%;
        position: fixed;
        top: 15px;
        right: 20px;
        z-index: 2;
        transition: all 300ms ease-in;
        cursor: pointer;
        :hover{
            background-color: rgba(90, 90, 90, 0.1);
        }
    }
    .top{
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10;
        height: 50px;
        width: 280px;
        background-color: whitesmoke;
        -webkit-animation: cssAnimation 400ms forwards; 
        animation: cssAnimation 400ms forwards;
        @media screen and (max-width:400px){
            width: 100vw;
            height: 65px;
        }
    }
    @keyframes cssAnimation {
        0%   {opacity: 0;}
        99%  {opacity: 0;}
        100% {opacity: 1;}
    }
    @media screen and (max-width: 825px){
        .close{
            background-color: #dc3546d0;
        }
    }
    @media screen and (max-width:825px){
        position: fixed;
        top:0;
        left: 0;
        .menu{
            display: block;
        }
        .logo{
            margin-left: 2px;
            img{
                height: 55px;
                width: 180px;
            }
        }
    }
`
    
export const Content = styled.div`
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    margin-right: 20px;
    a{
        padding: 0px 3px;
        /* z-index: 10 !important; */
        margin: 0px 15px;
        font-size: 18px;
        letter-spacing: 0.8px;
        text-decoration: none;
        border-bottom: 5px solid white;
        color: black;
        font-weight: 700;
        transition: all 400ms ease;
        cursor: pointer;
        :hover{
            border-bottom: 4px solid #DC3545;
        }
    }
    .active{
        border-bottom: 4px solid #DC3545;
    }
    .study-abroad{
        display: flex;
        align-items: center;
        :hover{
            border-bottom: 5px solid white;
        }
    }
    .drop-down{
        display: flex;
        flex-direction: column;
        z-index: 10;
        .country{
            display: flex;
            flex-direction: column;
            position: absolute;
            margin-left: 10px;
            top: 32px;
            width: 260px;
            pointer-events: none;
            opacity: 0;
            transform: translateY(-12px);
            transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
            background-color: #DC3545;
            padding: 5px 0px;
            border-radius: 6px;
            a{
                border-bottom: 2px solid black;
                padding: 7px;
                transition: all 200ms ease;
                :hover{
                    color: white;
                }
            }
            .ex{
                border-bottom: none;
            }
        }
        :hover{
            .country{
                display: flex;
                opacity: 1;
                transform: translateY(0);
                pointer-events: auto;
            }
        }
    }
    @media screen and (max-width:825px){
        display: none;
    }
    @media screen and (max-width:950px){
        a{
            margin: 0px 5px;
            font-size: 17px;
        }
    }
`