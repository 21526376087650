import React from 'react'
import { Content, Wrapper } from './about.style'

const AboutBanner = () => {
  return (
    <Wrapper>
        <Content>
            <div className='top'>
              <img src="/images/about.jpeg" alt="About Banner" className='image'/>
            </div>
            <div className='bottom'>
              <div className='heading'>
                  <h1>ABOUT US</h1>
              </div>
              <div className='cards'>
                <div className='card'>
                    <div className='line'></div>
                    <div className='title'>
                      <h3>Who Are We</h3>
                    </div>
                    <div className='content'>
                      <p>TRG Overseas Private Limited is a specialist firm for admissions in U.S., U.K., Canada, Australia, New Zealand, India, and a lot of other European countries, UAE and Iran, Africa and South East Asian immigration and nationality law. Provides comprehensive and meticulous service for corporate and individual clients assisting individuals to live and study in various countries.</p>
                    </div>
                </div>
                <div className='card'>
                    <div className='line'></div>
                    <div className='title'>
                      <h3>Our Vision</h3>
                    </div>
                    <div className='content'>
                      <p>To be the World’s Leading Overseas Education Consultancy at guiding students to their full potential.To provide the best Professional services and sincere guidance to our clients in most efficient and economical way to achieve their immigration plans.</p>
                    </div>
                </div>
                <div className='card'>
                    <div className='line'></div>
                    <div className='title'>
                      <h3>Our Goal</h3>
                    </div>
                    <div className='content'>
                      <p>
                        We aim to guide the students and provide them hassle-free & smooth Admission process for the University of their Choice. 
                      </p>
                      <p>
                      Our goal is to remain loyal to our clients and always exceed their expectations by being ethical, flexible, and reliable honest in services we provide.
                      </p>
                    </div>
                </div>
              </div>
            </div>
        </Content>
    </Wrapper>
  )
}

export default AboutBanner