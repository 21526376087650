import styled from 'styled-components'

export const Wrapper = styled.div``

export const Content = styled.div`
    padding: 20px 50px;
    padding-top: 50px;
    h1{
        font-weight: 600;
        word-spacing: 1.5px;
        letter-spacing: 1px;
        font-size: 28px;
        color: #DC4535;
    }
    p{
        padding: 7.5px 0px;
        color: #000000bc;
        letter-spacing: 0.6px;
        word-spacing: 1.2px;
        font-size: 17px;
    }
    li{
        color: #000000bc;
        letter-spacing: 0.6px;
        word-spacing: 1.2px;
        font-size: 17px;
    }
    a{
        text-decoration: none;
    }
    @media screen and (max-width: 800px){
        padding-top: 40px;
        p{
            padding: 5px 0px;
        }
    }
    @media screen and (max-width: 420px){
        padding: 20px;
        p{
            padding: 5px 0px;
            font-size: 15px;
            text-align: justify;
        }
        li{
            font-size: 15px;
        }
        h2{
            font-size: 18px;
            padding-bottom: 10px;
        }
        .none{
            display: none;
        }
    }
`
