import React from 'react'
import { Content, Wrapper } from './flags.style'

import { flags } from '../../data'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { active } from '../../redux/SASlice';

const Flags = () => {
  const dispatch = useDispatch();
  return (
    <Wrapper>
        <h1>WHERE YOU WANT TO STUDY?</h1>
        <Content>
            {flags.map((item) => (
                <NavLink to={item.nav} key={item.id} onClick={()=> dispatch(active())}>
                  <div className='box'>
                      <img src={item.img} alt={item.alt} />
                      <p>{item.title}</p>
                  </div>
                </NavLink>
            ))}
        </Content>
    </Wrapper>
  )
}

export default Flags