import styled from 'styled-components'

export const Wrapper = styled.div`
    background-color: #dc3545;
    .foot{
        width: 100vw;
        border-top: 1px solid #00000070;
        background-color: #00000070;
        padding: 6px 0px 3px 0px;
        h2{
            font-size: 18px;
            font-weight: 600;
            color: white;
            text-align: center;
        }
    }
    @media screen and (max-width: 500px){
        .foot{
            margin-top: 10px;
            h2{
                font-size: 13px;
                font-weight: 600;
            }
        }
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    .left{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .logo{
            img{
                width: 250px;
            }
        }
        .social{
            display: flex;
            padding: 20px;
            .icon{
                a{
                    color: white;
                }
                padding: 0px 10px;
            }
        }
    }
    .center{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 30px;
        h2{
            font-size: 24px;
            font-weight: 600;
            padding: 0px 15px;
        }
        .links{
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            height: 200px;
            a{
                text-decoration: none;
                font-size: 16px;
                font-weight: 500;
                color: white;
                padding: 5px 15px;
                :hover{
                    text-decoration: underline;
                }
            }
        }
    }
    .right{
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        h2{
            font-size: 24px;
            font-weight: 600;
            padding: 0px 15px;
        }
        p{
            padding-left: 15px;
            color: white;
            font-size: 16px;
            font-weight: 500;
        }
        .second{
            padding-left: 40px;
            padding-top: 0px;
            margin-top: -15px;
        }
        img{
            width: 250px;
            padding-left: 15px;
            padding-top: 20px;
        }
    }

    @media screen and (max-width: 950px){
        padding: 20px 0px;
        .center{
            display: none;
        }
        .left{
            align-items: center;
        }
    }

    @media screen and (max-width: 500px){
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 10px;
        .left{
            width: 100vw;
            .logo{
                img{
                    width: 120px;
                    padding-top: 0px;
                    padding-left: 0px;
                }
            }
            .social{
            display: flex;
            padding: 10px;
            .icon{
                a{
                    color: white;
                }
                padding: 0px 7.5px;
            }
        }
        }
        .right{
            width: 100vw;
            padding-left: 20px;
            h2{
                padding: 0px;
                padding-top: 10px;
                font-size: 16px;
                font-weight: 600;
            }
            p{
                padding-left: 0px;
                font-size: 14px;
                margin-bottom: 10px;
                font-weight: 500;
            }
            .second{
                padding-left: 27px;
            }
            img{
                padding-left: 0px;
                padding-top: 0px;
                width: 180px;
            }
        }
    }
`;