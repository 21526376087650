import React from 'react'
import { Content, Wrapper } from './uk.style'
import { ukR } from '../../data'

const UK = () => {
  return (
    <Wrapper>
        <div className='banner'>
            <div className='image'>
                <img src="/images/UK_1.jpg" alt="Study In UK"/>
            </div>
            <div className='text'>
                <h3>STUDY IN UK</h3>
                <p>
                    United Kingdom has been the dream destination of international students for decades and the reasons are obvious. It is home to the world’s most respected universities like Oxford and Cambridge. Infact, the advantage of studying in the UK are numerous.
                </p>
            </div>
        </div>
        <Content>
            <h3>Top Reasons To Choose UK For Overseas Studies</h3>
            {
                ukR.map((item) => (
                    <div className='reason' key={item.id}>
                        <div className='heading'>
                            <p>{item.title}</p>
                        </div>
                        <div className='content'>
                            <p>{item.content}</p>
                        </div>
                    </div>
                ))
            }
        </Content>
    </Wrapper>
  )
}

export default UK