import React from 'react'
import { useDispatch } from 'react-redux';
import { notActive } from '../../redux/SASlice';
import { NavLink } from 'react-router-dom'
import { Content, Wrapper } from './contact.style'

const ContactBanner = () => {
  const dispatch = useDispatch();
  return (
    <Wrapper>
        <Content>
            <h1>Interested in Studying / Visiting or Settling Abroad</h1>
            <p>The Right Guru Overseas is a trusted university partner with a large number of universities for different countries, and our international education consultants will ensure that you find a place on the right course at the chosen university. We can assist with your application for foundation, undergraduate or postgraduate programmes.</p>
            <NavLink to='/contact' onClick={()=> dispatch(notActive())}><button>Contact Us</button></NavLink>
        </Content>
    </Wrapper>
  )
}

export default ContactBanner