import styled from 'styled-components'

export const Wrapper = styled.div`
    .top{
        display: flex;
        flex-direction: column;
        align-items: center;
        h1{
            font-weight: 600;
            font-size: 36px;
        }
    }

    @media screen and (max-width: 825px){
        margin-top: 58px;
    }
    @media screen and (max-width: 600px){
        .top{
            padding: 10px;
            text-align: center;
            font-size: 15px;
            p{
                display: none;
            }
        }
    }
`;

export const Content = styled.div`
    display: flex;
    margin: 50px;
    border-radius: 10px;
    border: #dc3545 2px solid;

    input[type=date]:required:invalid::-webkit-datetime-edit {
        color: transparent;
    }
    input[type=date]:focus::-webkit-datetime-edit {
        color: black !important;
    }
    
    .left{
        padding: 25px;
        width: 55%;
        .full{
            display: flex;
            justify-content: space-between;
        }
        .half{
            width: 48%;
        }

        .success{
            font-weight: 600;
            padding: 5px 10px;
            background-color: #01ae01b7;
            border-radius: 4px;
            color: white;
        }

        input[type='date']:after {
            color: #aaa;
            content: attr(placeholder);
        }

        .submitBtn{
            padding: 8px 25px;
            margin-top: 20px;
            background-color: #dc3545;
            color: white;
            font-weight: 700;
            border-radius: 5px;
            border: none;
            outline: none;
            :hover{
                cursor: pointer;
            }

            :disabled{
                background-color: #dc3546b2;
                cursor: not-allowed;
            }
        }
    }
    .right{
        width: 45%;
        iframe{
            border-radius: 0px 10px 10px 0px;
        }
    }

    @media screen and (max-width: 825px){
        margin: 20px;
    }
    @media screen and (max-width: 600px){
        flex-direction: column;
        .left{
            width: 100%;
            padding: 8px;
            .half{
                width: 100%;
            }
            .full{
                display: block;
            }
            .btn{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                button{
                    margin-top: 10px;
                }
            }
        }
        .right{
            width: 100%;
            height: 500px;
            iframe{
                border-radius: 0px 0px 10px 10px;
            }
        }
    }
`;