import React from 'react';
import { createRoot,hydrateRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import store from './redux/store'
import { Provider } from 'react-redux'

const APP = (
  <Provider store={store}>
      <App />
  </Provider>
)
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, APP);
} else {
  const rt = createRoot(rootElement); // createRoot(container!) if you use TypeScript
  rt.render(APP);
}