import React from 'react'
import {Helmet} from "react-helmet";
import ContactBanner from '../components/contact'
import AboutBanner from '../components/about'
import Whyus from '../components/Why-us'
import { ScrollToTop } from '../utils'

const About = () => {
  return (
    <>
        <Helmet>
          <title>About Us | TRG Overseas</title>
          <meta name="description" content="Who we are, Our vision, Our goals" />
        </Helmet>
        <ScrollToTop/>
        <AboutBanner/>
        <Whyus/>
        <ContactBanner/>
    </>
  )
}

export default About