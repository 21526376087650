import { createSlice } from '@reduxjs/toolkit';

const intialState = {
    value: false,
}

const sidenavSlice = createSlice({
    name:'sidenav',
    initialState: intialState,
    reducers: {
        open: (state) =>{
            state.value = true;
        },
        close: (state) =>{
            state.value = false;
        },
    },
})

export const { open,close } = sidenavSlice.actions

export default sidenavSlice.reducer