import React from 'react'
import { Content, Wrapper } from './services.style'

const ServiceCards = () => {
  return (
    <Wrapper>
        <Content>
            <div className='row'>
                <div className='logo'>
                    <img src="/images/Study_1.webp" alt="Study Abroad"/>
                </div>
                <div className='text'>
                    <h3>Study Abroad</h3>
                    <p>The Right Guru Overseas Pvt Ltd offers guidance to students who are wishing to study abroad in countries such as Australia, Canada, New Zealand, Singapore and the UK.</p>
                </div>
            </div>
            <div className='row blue'>
                <div className='text'>
                    <h3>Tourist Visa</h3>
                    <p>Travel Visas are required to gain entry to most countries around the world. The Right Guru Overseas is always here to provide you various visa services which are based on your nationalities.</p>
                </div>
                <div className='logo'>
                    <img src="/images/Tourist_1.webp" alt="Tourist Visa"/>
                </div>
            </div>
            <div className='row pink'>
                <div className='logo'>
                    <img src="/images/Immigration-2.jpeg" alt="Immigration"/>
                </div>
                <div className='text'>
                    <h3>Immigration</h3>
                    <p>The Right Guru Overseas provide assistance to individuals who are wishing to settle abroad such as UK, Canada, Singapore, USA and Australia through immigration process. We offer FREE Immigration Assessment. We will help you throughout your process to reach your dream destination.</p>
                </div>
            </div>
        </Content>
    </Wrapper>
  )
}

export default ServiceCards