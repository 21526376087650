import React from 'react'
import { Content, Wrapper } from './footer.style'

import { useDispatch } from 'react-redux';
import { active, notActive } from '../../redux/SASlice';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { NavLink } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Footer = () => {
    const dispatch = useDispatch();
  return (
    <Wrapper>
        <Content>
            <div className='left'>
                <div className='logo'>
                    <img src="/images/pic_3.png" alt="The Right Guru Logo" />
                </div>
                <div className='social'>
                    <div className='icon'>
                        <a href="https://www.facebook.com/people/The-Right-Guru/100063461899383/" target="_blank" rel="noopener noreferrer"> <FacebookIcon fontSize='large'/> </a>
                    </div>
                    <div className='icon'>
                        <a href="https://www.instagram.com/therightguru/" target="_blank" rel="noopener noreferrer"> <InstagramIcon fontSize='large'/> </a>
                    </div>
                    <div className='icon'>
                        <a href="https://twitter.com/The_Right_Guru/" target="_blank" rel="noopener noreferrer"> <TwitterIcon fontSize='large'/> </a>
                    </div>
                    <div className='icon'>
                        <a href="https://www.linkedin.com/company/the-right-guru/" target='_blank' rel="noopener noreferrer"> <LinkedInIcon fontSize='large'/> </a>
                    </div>
                </div>
            </div>
            <div className='center'>
                <div>
                    <h2>Quick Links</h2>
                </div>
                <div className='links'>
                    <NavLink to='/' onClick={()=> dispatch(notActive())}>Home</NavLink>
                    <a href="https://therightguru.com/privacy" target='_blank' rel="noopener noreferrer" onClick={()=> dispatch(notActive())}>Privacy Policy</a>
                    <NavLink to='/services' onClick={()=> dispatch(notActive())}>Services</NavLink>
                    <NavLink to='/study-in-uk' onClick={()=> dispatch(active())}>Study in UK</NavLink>
                    <NavLink to='/study-in-usa' onClick={()=> dispatch(active())}>Study in USA</NavLink>
                    <NavLink to='/study-in-canada' onClick={()=> dispatch(active())}>Study in Canada</NavLink>
                    <NavLink to='/study-in-nz' onClick={()=> dispatch(active())}>Study in New Zealand</NavLink>
                    <NavLink to='/study-in-australia' onClick={()=> dispatch(active())}>Study in Austraila</NavLink>
                    <NavLink to='/study-in-india' onClick={()=> dispatch(active())}>Study in India</NavLink>
                    <NavLink to='/about' onClick={()=> dispatch(notActive())}>About Us</NavLink>
                </div>
            </div>
            <div className='right'>
                <div className='contact'>
                <h2>Contact Details</h2>
                <p> <RoomIcon/>&nbsp;42- SF Omaxe Galleria,Sector 14,</p>
                <p className='second'>Bahadurgrah - 124507, Delhi-NCR</p>
                <p> <RoomIcon/>&nbsp;AT- 311 Vardhmaan Sunder Plaza,</p>
                <p className='second'>SEC-12  Dwarka New Delhi - 110078</p>
                <p> <PhoneIcon/>&nbsp;9647-787878, 97-2898-4141</p>
                <p> <MailOutlineIcon/>&nbsp;contact@trgoverseas.com</p>
                <img src="https://i.ibb.co/Qfvn4z6/payment.png" alt="payment" />
                </div>
            </div>
        </Content>
            <div className='foot'>
                <h2>© 2024 trgoverseas.com Pvt Ltd | All Rights Reserved!</h2>
            </div>
    </Wrapper>
  )
}

export default Footer