import React from 'react'
import ContactBanner from '../components/contact'
import Canada from '../components/Canada'
import { ScrollToTop } from '../utils';
import { Helmet } from 'react-helmet';

const StudyCanada = () => {
  return (
    <>  
        <Helmet>
          <title>Study In Canada | TRG Overseas</title>
          <meta name="description" content="Canada is known to offer finest quality education at affordable tuition fee with degrees that are globally recognised. Solely not academics, an excellent quality of life, immense post-study work and immigration opportunities, Canada has it all, which also makes it a popular destination of study."/>
        </Helmet>
        <ScrollToTop/>
        <Canada/>
        <ContactBanner/>
    </>
  )
}

export default StudyCanada