import styled from "styled-components";

export const Wrapper = styled.div``;

export const Content = styled.div`
    height: auto;
    width: 100vw;
    margin: 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .row{
        margin: 10px;
        height: 40vh;
        padding: 20px;
        width: 90%;
        padding: 0;
        background-color: #ffff00a0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo{
            width: 26%;
            padding: 10px 40px;
            img{
                height: 100%;
                width: 100%;
                border-radius: 50%;
            }
        }
        .text{
            width: 65%;
            h3{
                font-size: 32px;
                font-weight: 600;
                color: black;
            }
            p{
                font-weight: 500;
                font-size: 16px;
            }
        }
    }
    .pink{
        background-color: #ffb6c1a0;
    }
    .blue{
        background-color: #b0e0e6a0;
        .text{
            padding: 40px;
        }
        .logo{
            padding-left: 0px;
        }
    }

    @media screen and (max-width: 950px){
        .row{
            width: 95%;
            height: 35vh;
            .logo{
                padding: 15px;
            }
        }
    }
    @media screen and (max-width: 450px){
        .row{
            width: 95%;
            height: auto;
            padding: 10px;
            flex-direction: column-reverse;
            .logo{
                width: 60%;
                padding: 0;
                padding: 10px;
            }
            .text{
                width: 95%;
                padding: 0;
                padding-top: 15px;
                text-align: justify;
                h3{
                    font-size: 24px;
                }
                p{
                    font-size: 16px;
                }
            }
        }
        .blue{
            flex-direction: column;
            .text{
                padding: 0;
                padding-top: 15px;
            }
        }
    }
`;