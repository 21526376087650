import React from 'react'
import { Wrapper } from './service.style'

const Service = () => {
  return (
    <Wrapper>
        <div className='banner'>
        </div>
        <div className='headlines'>
            <h1>OUR SERVICES</h1>
            <h3>We offer unbiased, comprehensive, genuine, ethical and quality professional consultation services to each of our core areas e.g. Study Visa, English Language Training, Tourist Visa and Immigration Visa</h3>
        </div>
    </Wrapper>
  )
}

export default Service