import styled from 'styled-components'

export const Wrapper = styled.div`
    /* background: url('https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80') no-repeat; */
    /* background: url('https://images.unsplash.com/photo-1553367989-1f8a5d29ee08?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80') no-repeat; */
    background: url('https://images.unsplash.com/photo-1508955910516-f9df01540949?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1229&q=80') no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 400px;
    @media screen and (max-width: 700px){
        height: 70vh;
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    h1{
        color: white;
        text-align: center;
        font-weight: 700;
        padding-bottom: 20px;
        letter-spacing: 1px;
    }
    p{
        text-align: center;
        width: 60vw;
        color: whitesmoke;
        font-size: 16px;
        letter-spacing: 0.6px;
        padding: 10px;
        font-weight: 500;
    }
    button{
        background: transparent;
        border: none;
        text-decoration: none;
        color: white;
        font-weight: 600;
        font-size: 22px;
        outline: 2px solid black;
        padding: 10px 40px;
        margin-top: 20px;
        transition: all 400ms ease;
        :hover{
            background-color: #000000bc;
        }
    }
    @media screen and (max-width: 800px){
        height: 70vh;
        p{
            font-size: 15px;
            width: 80vw;
            /* text-align: justify; */
        }
    }
    @media screen and (max-width: 450px){
        height: 60vh;
        justify-content: flex-start;
        h1{
            font-size: 20px;
            padding-bottom: 10px;
            padding-top: 30px;
        }
        p{
            font-size: 13px;
            font-weight: 600;
            width: 100vw;
            /* text-align: justify; */
        }
        button{
            padding: 10px 30px;
            background-color: #000000bc;
            font-size: 18px;
        }
    }
`