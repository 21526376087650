import React from 'react'
import { Wrapper } from './why.style'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { why } from '../../data';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
}));
  
const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.1rem' }} style={{color:"white"}}/>}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
}));
  
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Whyus = () => {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Wrapper>
        <div className='content'>
            <div className='banner'>
                <div className='circle'>
                    <h1>WHY</h1>
                    <h1>CHOOSE</h1>
                    <h1>US?</h1>
                </div>
            </div>
            <div className='heading'>
                <h1>WHY CHOOSE US?</h1>
            </div>
            <div className='half accordion'>
              {
                why.map((item) => (
                  <Accordion key={item.id} expanded={expanded === `${item.id}`} onChange={handleChange(`${item.id}`)}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="head">
                      <Typography>{item.doubt}</Typography>
                      </AccordionSummary>
                      <AccordionDetails className='bottom'>
                      <Typography>
                          {item.ans}
                      </Typography>
                      </AccordionDetails>
                  </Accordion>
                ))
              }
            </div>
        </div>
    </Wrapper>
  )
}

export default Whyus