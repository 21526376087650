import React from 'react'
import Flags from '../components/Flags'
import Highlights from '../components/Highlights'
import Intro from '../components/Intro'
import Guide from '../components/Guide'
import Testimonials from '../components/Testimonials'
import ContactBanner from '../components/contact'
import { ScrollToTop } from '../utils'
import { Helmet } from 'react-helmet'

const Home = () => {
  return (
    <>  
        <Helmet>
          <title>The Right Guru Overseas</title>
          <meta name="description" content="The Right Guru Overseas helps students who are interested in studing abroad and settling there! We will help you throughout your process to reach your dream destination." />
        </Helmet>
        <ScrollToTop/>
        <Highlights/>
        <Intro/>
        <Flags/>
        <Guide/>
        <Testimonials/>
        <ContactBanner/>
    </>
  )
}

export default Home
