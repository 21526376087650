import React from 'react'
import { Content, Wrapper } from './canada.style'
import { canadaR } from '../../data'

const Canada = () => {
  return (
    <Wrapper>
        <div className='banner'>
            <div className='image'>
                <img src="/images/CANADA_1.jpeg" alt="Study In Canada"/>
            </div>
            <div className='text'>
                <h3>STUDY IN CANADA</h3>
                <p>
                    Canada is known to offer finest quality education at affordable tuition fee with degrees that are globally recognised. Solely not academics, an excellent quality of life, immense post-study work and immigration opportunities, Canada has it all, which also makes it a popular destination of study.
                </p>
            </div>
        </div>
        <Content>
            <h3>Top Reasons To Choose Canada For Overseas Studies</h3>
            {
                canadaR.map((item) => (
                    <div className='reason' key={item.id}>
                        <div className='heading'>
                            <p>{item.title}</p>
                        </div>
                        <div className='content'>
                            <p>{item.content}</p>
                        </div>
                    </div>
                ))
            }
        </Content>
    </Wrapper>
  )
}

export default Canada