import React from 'react'
import { NavLink } from 'react-router-dom'
import { Content, Wrapper } from './intro.style'

const Intro = () => {
  return (
    <Wrapper>
        <Content>
            <h1>The Right Guru Overseas is one stop solution for all your Study Abroad, IELTS, PTE, Tourist Visa and Immigration needs</h1>
            <p>The Right Guru Private Limited is a specialist firm for admissions in U.S., U.K., Canada, Australia, New Zealand, India, and a lot of other European countries, UAE and Iran, Africa and South East Asian immigration and nationality law. For nearly 2 years, the dedicated and experienced team has provided a comprehensive and meticulous service for corporate and individual clients assisting individuals to live and study in various countries.</p>
            <p className='none'>
                We offer high-quality and professional university admission services to the perspective students across the world. We provide English coaching to aspiring students wishing to embark in their next step of professional journey. We also provide Visa assistance to clients who wants to visit  or settle abroad.
            </p>
            <p className='none'>
                We have an Operations Office in Delhi, India and associate offices in UK, Australia, Canada, New Zealand, a lot of European and South East Asian countries as well as countries in the African continent.  We are qualified attorneys licensed to practice immigration law collectively in New York, United Kingdom, Australia, Canada, New Zealand, Europe, the Far East and South East Asia.
            </p>
            <p>Our offices work in the following manner:</p>
            <ul>
                <li>Evaluating eligibility for student visa</li>
                <li>Informing the client as to the most updated regulations</li>
                <li>Examining alternative university options</li>
                <li>Assistance in locating all necessary documents</li>
                <li>Preparing and submitting the application to the proper authorities</li>
                <li>Working with the proper immigration authorities and universities in the country of destination.</li>
            </ul>
            <p><b>If you are unsure about rules, policy or regulations, please contact us and we will be able to inform you of which will apply to your case. If you want to get your self check eligible for admission in particular university <NavLink to="/contact">contact us</NavLink>.</b></p>
        </Content>
    </Wrapper>
  )
}

export default Intro
