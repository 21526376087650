import { BrowserRouter as Router ,Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Services from './pages/Services'
import Contact from './pages/Contact'
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import About from './pages/About';
import StudyUK from './pages/StudyUK';
import StudyUSA from './pages/StudyUSA';
import StudyCanada from './pages/StudyCanada';
import StudyNZ from './pages/StudyNZ';
import StudyAus from './pages/StudyAus';
import StudyIND from './pages/StudyIndia';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <div className='App' style={{margin:'0px', padding:'0px'}}>
        <Navbar/>
        <Routes>

          <Route path='/' element={<Home/>} />
          <Route path='/services' element={<Services/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/study-in-uk' element={<StudyUK/>} />
          <Route path='/study-in-usa' element={<StudyUSA/>} />
          <Route path='/study-in-canada' element={<StudyCanada/>} />
          <Route path='/study-in-nz' element={<StudyNZ/>} />
          <Route path='/study-in-australia' element={<StudyAus/>} />
          <Route path='/study-in-india' element={<StudyIND/>} />

        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
