import React from 'react'
import ContactBanner from '../components/contact'
import Aus from '../components/Aus'
import { ScrollToTop } from '../utils'
import { Helmet } from 'react-helmet'

const StudyAus = () => {
  return (
    <>  
        <Helmet>
          <title>Study In Australia | TRG Overseas</title>
          <meta name="description" content="Australia has the third highest number of international students in the world, behind only the United Kingdom and the United States. Australia has seven of the top 100 universities in the world!"/>
        </Helmet>
        <ScrollToTop/>
        <Aus/>
        <ContactBanner/>
    </>
  )
}

export default StudyAus