import React from 'react'
import { Helmet } from 'react-helmet';
import ContactBanner from '../components/contact';
import Service from '../components/Service';
import ServiceCards from '../components/ServiceCards';
import { ScrollToTop } from '../utils';

const Services = () => {
  return (
    <div>
      <Helmet>
          <title>Our Services | TRG Overseas</title>
          <meta name="description" content="We offer unbiased, comprehensive, genuine, ethical and quality professional consultation services to each of our core areas e.g. Study Visa, English Language Training, Tourist Visa and Immigration Visa" />
      </Helmet>
      <ScrollToTop/>
      <Service/>
      <ServiceCards/>
      <ContactBanner/>
    </div>
  )
}

export default Services