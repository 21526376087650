import styled from 'styled-components'

export const Wrapper = styled.div`
    h1{
        font-weight: 700;
        color: black;
        text-align: center;
        word-spacing: 10px;
        letter-spacing: 1px;
        font-size: 28px;
    }
    @media screen and (max-width: 450px){
        h1{
            font-weight: 700;
            font-size: 20px;
            word-spacing: 2px;
            padding-bottom: 10px;
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100vw;
    .box{
        width: 400px;
        height: 250px;
        margin: 30px 25px;
        position: relative;
        display: flex;
        justify-content: center;
        p{
            position: absolute;
            bottom: 0;
            width: 396px;
            text-align: center;
            /* color: #DC3545; */
            color: black;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 1px;
            /* background-color: #00000080; */
            background-color: #ffffff80;
        }
        transition: all 300ms ease;
        :hover{
            cursor: pointer;
            transform: scale(1.05);
        }
    }
    img{
        width: 400px;
        height: 250px;
        opacity: 0.9;
        object-fit: cover;
        object-position: center;
        border: 2px solid #000000bc;
        box-shadow: #00000070 2px 2px 8px 1px;
    }

    @media screen and (max-width: 900px){
        .box{
            width: 45vw;
            height: 180px;
            margin: 10px;
            display: flex;
            justify-content: center;
            p{
                width: 44.5vw;
                font-size: 14px;
            }
        }
        img{
            width: 45vw;
            height: 180px;
        }
    }

    @media screen and (max-width: 650px){
        .box{
            width: 45vw;
            height: 140px;
            margin: 8px;
            display: flex;
            justify-content: center;
            p{
                width: 44.5vw;
                font-size: 14px;
            }
        }
        img{
            width: 45vw;
            height: 140px;
        }
    }

    @media screen and (max-width: 450px){
        .box{
            width: 45vw;
            height: 100px;
            margin: 10px 5px;
            p{
                width: 45vw;
                font-size: 12px;
                bottom: -15px;
                border-left: 1px solid #00000069;
                border-right: 1px solid #00000069;
            }
        }
        img{
            width: 45vw;
            height: 100px;
            border: 1px solid #000000bc;
        }
    }
`;