import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100vw;
    height: 70vh;
    background-color: whitesmoke;
    display: flex;
    position: relative;
    overflow: hidden;
    /* margin-top: 74px; */
    @media screen and (max-width: 825px) {
        margin-top: 58px;
        height: 60vh;
    }
    @media screen and (max-width: 420px) {
        margin-top: 58px;
        height: 45vh;
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    #small{
        display: none;
    }
    /* z-index: -1; */
    .bg{
            img{
                height: 70vh;
                width: 100vw;
                object-fit: cover;
                object-position: center; 
                opacity: 0.95; 
            }
    }
    .back{
        background-color: #000000b0;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100vw;
        display: flex;
        justify-content: center;
        padding-bottom: 4px;
        padding-top: 14px;
        p{
            width: 80vw;
            padding: 0px 20px;
            font-size: 16px;
            letter-spacing: 0.6px;
        }
    }
    @media screen and (max-width: 825px) {
        #big{
            display: none;
        }
        #small{
            display: block;
        }
        .bg{
            img{
                height: 60vh;
                width: 100vw;
                object-fit: cover;
                object-position: center; 
            }
        }
        .back{
            padding-bottom: 0px;
            padding-top: 10px;
            margin-bottom: 0px;
            p{
                width: 90vw;
                padding: 0px 10px;
                font-size: 15px;
            }
        }
    }
    @media screen and (max-width: 420px) {
        .bg{
            img{
                height: 45vh;
                width: 100vw;
                object-fit: cover;
                object-position: center; 
            }
        }
        .back{
            padding-bottom: 0px;
            padding-top: 8px;
            margin-bottom: -8px;
            p{
                width: 100vw;
                padding: 0px 5px;
                font-size: 12px;
                font-weight: 700;
            }
        }
    }

    /* .icon{
        height: 55px;
        width: 55px;
        padding: 10px;
        color: #c1c1c1;
        cursor: pointer;
        z-index: 2;
        :hover{
            height: 60px;
            width: 60px;
            color: white;
        }
    }
    .left{
        position: absolute;
        left: 0px;
    }
    .right{
        position: absolute;
        right: 0px;
    } */
`;