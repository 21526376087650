import styled from "styled-components";

export const Wrapper = styled.div`
    .content{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e3bfff;
        background: linear-gradient(0deg, #e3bfff 0%, #ca88fc 100%);
        .half{
            width: 50%;
            height: auto;
            margin: 25px;
            padding: 25px;
            .head{
                background-color: #550b8eb0;
                color: white;
                outline: none;
                p{
                    font-size: 1.05rem;
                    font-weight: 600;
                    letter-spacing: 0.5px;
                }
            }
            .bottom{
                color: #96000f;
                background-color: whitesmoke;
                p{
                    letter-spacing: 0.5px;
                    font-size: 1.02rem;
                    font-weight: 500;
                    padding: 0px 15px;
                }
            }
        }
        .banner{
            display: flex;
            .circle{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 400px;
                width: 400px;
                margin: 50px;
                background-color: whitesmoke;
                border-radius: 50%;
                h1{
                    font-size: 64px;
                    font-weight: 800;
                    color: #550b8eb0;
                }
            }
        }
        .heading{
            display: none;
            h1{
                font-size: 42px;
                font-weight: 700;
                padding-top: 30px;
                color: whitesmoke;
            }
        }
    }

    @media screen and (max-width: 900px){
        .content{
            flex-direction: column;
            .banner{
                display: none;
            }
            .half{
                width: 70%;
            }
            .heading{
                display: block;
            }
        }
    }

    @media screen and (max-width: 450px){
        .content{
            .half{
                width: 96%;
                .bottom{
                    p{
                        padding: 0;
                        text-align: justify;
                    }
                }
            }
            .heading{
                h1{
                    font-size: 28px;
                    font-weight: 700;
                }
                margin-bottom: -20px;
            }
        }   
    }
`;
