import React from 'react'
import { Content, Wrapper } from './ind.style'
import { indR } from '../../data'

const IND = () => {
  return (
    <Wrapper>
        <div className='banner'>
            <div className='image'>
                <img src="/images/IND_1.jpeg" alt="Study In India"/>
            </div>
            <div className='text'>
                <h3>STUDY IN INDIA</h3>
                <p>
                    India's diversity and history offer a wide array of options for any student. India's educational system is as vast and diverse as the country itself. While living in India can be challenging for international students, it is an affordable and rewarding experience.
                </p>
            </div>
        </div>
        <Content>
            <h3>Top Reasons To Choose India For Overseas Studies</h3>
            {
                indR.map((item) => (
                    <div className='reason' key={item.id}>
                        <div className='heading'>
                            <p>{item.title}</p>
                        </div>
                        <div className='content'>
                            <p>{item.content}</p>
                        </div>
                    </div>
                ))
            }
        </Content>
    </Wrapper>
  )
}

export default IND