import React from 'react'
import { Content, Wrapper } from './nz.style'
import { nzR } from '../../data'

const NZ = () => {
  return (
    <Wrapper>
        <div className='banner'>
            <div className='image'>
                <img src="/images/NZ_1.jpeg" alt="Study In New Zealand"/>
            </div>
            <div className='text'>
                <h3>STUDY IN NEW ZEALAND</h3>
                <p>
                    The education system in New Zealand is enormously diverse and one of the best in the world, maintaining excellent standards in literacy, mathematics and sciences and ranking well consistently by global standards.
                </p>
            </div>
        </div>
        <Content>
            <h3>Top Reasons To Choose New Zealand For Overseas Studies</h3>
            {
                nzR.map((item) => (
                    <div className='reason' key={item.id}>
                        <div className='heading'>
                            <p>{item.title}</p>
                        </div>
                        <div className='content'>
                            <p>{item.content}</p>
                        </div>
                    </div>
                ))
            }
        </Content>
    </Wrapper>
  )
}

export default NZ