import React from 'react'
import { Wrapper,Content } from './Navbar.style'
import { NavLink } from 'react-router-dom';
import SideNav from '../SideNav';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import { useSelector, useDispatch } from 'react-redux';
import { active, notActive } from '../../redux/SASlice';
import { open, close } from '../../redux/sidenavSlice';

const Navbar = () => {

    const sidenav = useSelector((state) => state.sidenav.value);
    const sa = useSelector((state) => state.sa.value);
    const dispatch = useDispatch();
    
    return (
        <>
        <Wrapper>
            <div className='logo' >
                <NavLink to='/' onClick={()=> dispatch(notActive())}>
                    <img src='/images/pic_4.png' alt='logo'/>
                </NavLink>
            </div>
            <Content>
                <NavLink to='/' className='none' activeclassname='active' onClick={()=>dispatch(notActive())} >Home</NavLink>
                <NavLink to='/services' className='none' activeclassname='active' onClick={()=>dispatch(notActive())}>Our Services</NavLink>
                <div className='drop-down'>
                    <NavLink to="/study-in-uk" className={sa ? 'none study-abroad active' : 'none study-abroad'} >Study Abroad <ArrowDropDownIcon/></NavLink>
                    <div className='country'>
                        <NavLink to='/study-in-uk' className='none' onClick={()=>dispatch(active())}>Study in UK</NavLink>
                        <NavLink to='/study-in-usa' className='none' onClick={()=>dispatch(active())}>Study in USA</NavLink>
                        <NavLink to='/study-in-canada' className='none' onClick={()=>dispatch(active())}>Study in Canada</NavLink>
                        <NavLink to='/study-in-nz' className='none' onClick={()=>dispatch(active())}>Study in New Zealand</NavLink>
                        <NavLink to='/study-in-australia' className='none' onClick={()=>dispatch(active())}>Study in Australia</NavLink>
                        <NavLink to='/study-in-india' className='none ex' onClick={()=>dispatch(active())}>Study in India</NavLink>
                    </div>
                </div>
                <NavLink to='/about' className='none' activeclassname='active' onClick={()=>dispatch(notActive())}>About Us</NavLink>
                <NavLink to='/contact' className='none' activeclassname='active' onClick={()=>dispatch(notActive())}>Contact Us</NavLink>
            </Content>
            <div className='menu' onClick={()=> dispatch(open())}>
                <MenuIcon fontSize='large'/>
            </div>
            {sidenav && 
                <div className='top'>
                    <div className='close' onClick={()=> dispatch(close())}>
                        <CloseIcon/>
                    </div>
                </div>
            }
            <SideNav show={sidenav} />
        </Wrapper>
        </>
    )
}

export default Navbar