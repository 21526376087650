import React from 'react'
import { Content, Wrapper } from './aus.style'
import { ausR } from '../../data'

const Aus = () => {
  return (
    <Wrapper>
        <div className='banner'>
            <div className='image'>
                <img src="/images/AUS_1.jpeg" alt="Study In Australia"/>
            </div>
            <div className='text'>
                <h3>STUDY IN AUSTRALIA</h3>
                <p>
                    Australia has the third highest number of international students in the world, behind only the United Kingdom and the United States. Australia has seven of the top 100 universities in the world!
                </p>
            </div>
        </div>
        <Content>
            <h3>Top Reasons To Choose Australia For Overseas Studies</h3>
            {
                ausR.map((item) => (
                    <div className='reason' key={item.id}>
                        <div className='heading'>
                            <p>{item.title}</p>
                        </div>
                        <div className='content'>
                            <p>{item.content}</p>
                        </div>
                    </div>
                ))
            }
        </Content>
    </Wrapper>
  )
}

export default Aus