import React from 'react'
import { Wrapper,Content} from './sidenav.style'

import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { active, notActive } from '../../redux/SASlice';
import { close } from '../../redux/sidenavSlice';

function SideNav({ show }) {
    const dispatch = useDispatch();

    return (
        <Wrapper show={show}>
            <div className='content'>
            <Content>
                <NavLink to='/' activeclassname='active' onClick={() => {dispatch(close()); dispatch(notActive())}}><li className='half'>Home</li></NavLink>
                <NavLink to='/services' activeclassname='active' onClick={() => {dispatch(close()); dispatch(notActive())}}><li className='half'>Our Services</li></NavLink>
                <NavLink to='/study-in-uk' activeclassname='active' onClick={() => {dispatch(close()); dispatch(active())}}><li className='half'>Study in UK</li></NavLink>
                <NavLink to='/study-in-usa' activeclassname='active' onClick={() => {dispatch(close()); dispatch(active())}}><li className='half'>Study in USA</li></NavLink>
                <NavLink to='/study-in-canada' activeclassname='active' onClick={() => {dispatch(close()); dispatch(active())}}><li className='half'>Study in Canada</li></NavLink>
                <NavLink to='/study-in-nz' activeclassname='active' onClick={() => {dispatch(close()); dispatch(active())}}><li className='half'>Study in New Zealand</li></NavLink>
                <NavLink to='/study-in-australia' activeclassname='active' onClick={() => {dispatch(close()); dispatch(active())}}><li className='half'>Study in Australia</li></NavLink>
                <NavLink to='/study-in-india' activeclassname='active' onClick={() => {dispatch(close()); dispatch(active())}}><li className='half'>Study in India</li></NavLink>
                <NavLink to='/about' activeclassname='active' onClick={() => {dispatch(close()); dispatch(notActive())}}><li className='half'>About Us</li></NavLink>
                <NavLink to='/contact' activeclassname='active' onClick={() => {dispatch(close()); dispatch(notActive())}}><li className='half'>Contact Us</li></NavLink>
            </Content>
            </div>
        </Wrapper>
    )
}

export default SideNav