import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100vw;
    height: 50vh;
    .banner{
        background-image: url("/images/service.webp");
        filter: blur(3px);
        -webkit-filter: blur(3px);
        width: 100vw;
        height: 50vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .headlines{
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
        color: white;
        font-weight: bold;
        border: 3px solid #f1f1f1;
        position: absolute;
        top: 34%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
        width: 80%;
        padding: 20px;
        text-align: center;
        h1{
            font-weight: 700;
            font-size: 48px;
            color: whitesmoke;
            margin-bottom: 20px;
        }
        h3{
            font-size: 22px;
            letter-spacing: 0.6px;
        }
    }

    @media screen and (max-width: 825px){
        .headlines{
            top: 28%;
            padding: 10px;
            h1{
                font-size: 32px;
            }
            h3{
                font-size: 16px;
            }
        }
    }
    /* @media screen and (max-width: 500px){
        .headlines{
            padding: 10px;
            h1{
                font-size: 32px;
            }
            h3{
                font-size: 16px;
            }
        }
    } */
`;