import React from 'react'
import { Helmet } from 'react-helmet'
import ContactBanner from '../components/contact'
import UK from '../components/Uk'
import { ScrollToTop } from '../utils'

const StudyUK = () => {
  return (
    <>
        <Helmet>
          <title>Study In United Kingdom | TRG Overseas</title>
          <meta name="description" content="United Kingdom has been the dream destination of international students for decades and the reasons are obvious. It is home to the world’s most respected universities like Oxford and Cambridge. Infact, the advantage of studying in the UK are numerous."/>
        </Helmet>
        <ScrollToTop/>
        <UK/>
        <ContactBanner/>
    </>
  )
}

export default StudyUK