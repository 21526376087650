import React from 'react'
import { sliderItems } from '../../data';

// import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
// import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { Content, Wrapper } from './highlights.style';

import Carousel from 'react-bootstrap/Carousel'

const Highlights = () => {
    // const [index, setIndex] = useState(0);

    // const handleClick = (direction) =>{
    //     if(direction === "left"){
    //         setIndex(index > 0 ? index-1 : 5);
    //     } else{
    //         setIndex(index < 5 ? index+1 : 0);
    //     }
    // } 

  return (
    <Wrapper>
        <Content>
        {/* <ArrowCircleLeftOutlinedIcon className='icon left' onClick={() => handleClick("left")}/>
            <Slide index={index}>
                {sliderItems.map((item) => (
                    <div className='box'>
                        <div className='bg'> 
                            <img src={item.img} alt={item.alt}/> 
                        </div> 
                        <div className='details'> 
                            <p className='desc'>{item.desc}</p> 
                        </div>
                    </div>
                ))}
            </Slide>
        <ArrowCircleRightOutlinedIcon className='icon right' onClick={() => handleClick("right")}/> */}

        <Carousel id='big' indicators={false} touch={true}>
            {sliderItems.map((item) => (
                <Carousel.Item style={{width:'100vw'}} key={item.id} interval={10000}>
                    <div className='bg'> 
                        <img src={item.img} alt={item.id}/> 
                    </div> 
                    <Carousel.Caption className='back'>
                        <p className='desc'>{item.desc}</p> 
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>
        <Carousel controls={false} indicators={false} touch={true} id='small'>
            {sliderItems.map((item) => (
                <Carousel.Item style={{width:'100vw'}} key={item.id} interval={8000}>
                    <div className='bg'> 
                        <img src={item.img} alt={item.id}/> 
                    </div> 
                    <Carousel.Caption className='back'>
                        <p className='desc'>{item.desc}</p> 
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>

        </Content>
    </Wrapper>
  )
}

export default Highlights