import styled from 'styled-components'

export const Wrapper = styled.div`
    /* background-color: whitesmoke; */
    padding-bottom: 30px;
    h1{
        text-align: center;
        font-weight: 700;
        padding: 20px 0px;
        padding-top: 40px;
        word-spacing: 8px;
        letter-spacing: 1px;
        font-size: 28px;
    }
    @media screen and (max-width: 500px){
        margin-top: 20px;
        h1{
            padding: 0px 5px;
            padding-top: 20px;
            word-spacing: 2px;
            letter-spacing: 1px;
            font-size: 20px;
            color: black;
        }
    }
`

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .box{
        width: 75vw;
        height: auto;
        margin: 20px 0px;
        padding: 30px;
        background-color: aliceblue;
        box-shadow: #00000070 2px 2px 4px 2px;
        border-radius: 5px;
        display: flex;
        h2{
            font-size: 24px;
            font-weight: 600;
        }
        p{
            color: #000000bc;
            text-align: justify;
        }
    }
    #i1, #i2, #i3, #i4, #i5, #i6{
        font-size: 50px;
        margin-right: 30px;
    }

    @media screen and (max-width: 500px){
        .box{
            width: 94vw;
            flex-direction: column;
            padding: 0px 8px;
            margin: 8px 0px;
            h2{
                font-size: 16px;
                /* padding-top: 10px; */
            }
            P{
                font-size: 13px;
            }
        }
        #i1, #i2, #i3, #i4, #i5, #i6{
            font-size: 25px;
            margin: 10px;
            margin-right: 0px;
        }
        .icon{
            /* display: none; */
        }
    }
`