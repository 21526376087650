import styled from 'styled-components'

export const Wrapper = styled.div`
    background-color: #00000020;
    /* background-color: #EC3545bc; */
    padding-top: 40px;
    h1{
        text-align: center;
        font-weight: 700;
        letter-spacing: 1.5px;
    }
    h2{
        text-align: center;
        font-size: 24px;
        color: #000000a0;
    }
    .box{
        width: 90vw;
        margin-left: 5vw;
    }
    @media screen and (max-width: 480px){
        padding-top: 20px;
        h1{
            font-size: 20px;
            letter-spacing: 1px;
        }
        h2{
            font-size: 14px;
        }
        .box{
            width: 82vw;
            margin-left: 9vw;
        }
    }
`

export const Content = styled.div`
    margin: 20px 0px;
    .card{
        margin: 30px;
        border-radius: 0px;
        box-shadow: #00000060 1px 1px 4px 2px;
        border: 1px solid #000000bc;
    }
    .head{
        display: flex;
        align-items: center;
        height: 60px;
        #picon{
            font-size: 40px;
            margin: 0px 20px;
        }
        h3{
            font-size: 20px;
            font-weight: 600;
        }
        p{
            font-size: 15px;
            color: #000000bc;
            margin-top: -10px;
            margin-bottom: -5px;
        }
        background-color: #00000010;
    }
    .desc{
        /* height: 200px; */
        padding: 20px 25px;
        text-align: justify;
    }
    @media screen and (max-width: 920px){
        .card{
            margin: 20px;
        }
        .desc{
            padding: 15px;
            height: 200px;
        }
        .head{
            height: 50px;
            #picon{
                font-size: 30px;
                margin: 0px 10px;
            }
            h3{
                font-size: 18px;
                margin-top: -5px;
            }
            p{
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 480px){
        .card{
            margin: 5px;
        }
        .desc{
            padding: 10px;
            height: auto;
        }
        .head{
            height: 50px;
            #picon{
                font-size: 30px;
                margin: 0px 10px;
            }
            h3{
                font-size: 16px;
                margin-top: -5px;
            }
            p{
                font-size: 12px;
            }
        }
        .desc{
            p{
                font-size: 13px;
                font-weight: 500;
            }
        }
    }
`