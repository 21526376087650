import styled from "styled-components";

export const Wrapper = styled.div`

`;

export const Content = styled.div`
    width: 100%;
    .top{
        height: 40vh;
        .image{
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 40vh;
            opacity: 0.6;
        }
    }
    .bottom{
        margin-top: -25vh;
        margin-bottom: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .heading{
            margin-bottom: 50px;
            h1{
                font-weight: 900;
                font-size: 64px;
                color: black;
                letter-spacing: 1px;
                word-spacing: 10px;
            }
        }
        .cards{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 75%;
            .card{
                border: none;
                border-radius: 0;
                box-shadow: 3px 2px 10px #dcdcdc;
                outline: none;
                width: 31%;
                padding: 30px;
                background-color: whitesmoke;
                .line{
                    width: 40px;
                    height: 10px;
                    background-color: white;
                    border-radius: 2px;
                }
                .title{
                    padding: 20px 0px;
                    h3{
                        /* color: #4c4c4c; */
                        font-weight: 800;
                        color: #dc4535;
                    }
                }
                .content{
                    p{
                        color: #000000cc;
                        text-align: justify;
                        font-size: 1.05rem;
                        letter-spacing: 0.6px;
                    }
                }
            }
        }

    }

    @media screen and (max-width: 850px){
        .bottom{
            .cards{
                width: 60%;
                flex-direction: column;

                .card{
                    width: 100%;
                    margin-bottom: 40px;
                }
            }
        }
    }
    @media screen and (max-width: 450px){
        .bottom{
            margin-bottom: 50px;
            .heading{
                h1{
                    font-size: 42px;
                }
            }
            .cards{
                width: 80%;
                flex-direction: column;

                .card{
                    width: 100%;
                    margin-bottom: 30px;
                }
            }
        }
    }
`;