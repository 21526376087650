import React from 'react'
import { Helmet } from 'react-helmet'
import ContactBanner from '../components/contact'
import USA from '../components/Usa'
import { ScrollToTop } from '../utils'

const StudyUSA = () => {
  return (
    <>
        <Helmet>
          <title>Study In America | TRG Overseas</title>
          <meta name="description" content="United States of America boasts of one the world's biggest education systems. They have excellent programmes in all the disciplines they have to offer."/>
        </Helmet>
        <ScrollToTop/>
        <USA/>
        <ContactBanner/>
    </>
  )
}

export default StudyUSA