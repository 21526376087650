import styled from 'styled-components'

export const Wrapper = styled.div`
    .banner{
        display: flex;
        width: 100vw;
        margin: 25px;
        .image{
            img{
                object-fit: cover;
                object-position: center;
                height: 40vh;
                width: 50vw;
            }
        }
        .text{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            h3{
                text-align: center;
                color: #dc3545;
                font-size: 48px;
                font-weight: 800;
            }
            p{
                width: 70%;
                text-align: center;
                font-size: 1.05rem;
                padding: 10px;
            }
        }
    }

    @media screen and (max-width: 825px){
        .banner{
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0;
            margin-top: 55px;
            .image{
                img{
                    width: 100%;
                }
            }
            .text{
                h3{
                    font-size: 42px;
                }
                p{
                    width: 80%;
                }
            }
        }
    }

    @media screen and (max-width: 450px){
        .banner{
            .image{
                img{
                    height: 30vh;
                }
            }
            .text{
                h3{
                    font-size: 38px;
                    font-weight: 700;
                }
                p{
                    width: 90%;
                }
            }
        }
    }
`

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 40px;
    h3{
        text-align: center;
        font-size: 38px;
        color: darkred;
        font-weight: 700;
    }
    .reason{
        width: 75vw;
        margin-top: 20px;

        .heading{
            p{
                font-size: 24px;
                font-weight: 600;
                color: rebeccapurple;
            }
        }
        .content{
            margin-top: -5px;
            p{
                text-align: justify;
            }
        }
    }

    @media screen and (max-width: 450px){
        h3{
            font-size: 32px;
            font-weight: 600;
        }
        .reason{
            width: 90vw;
        }
    }
`